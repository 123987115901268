/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';

* {
  font-family: 'Inter';
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center
  }

  .flex-col-center {
    @apply flex flex-col items-center justify-center
  }

  .grey-input {
    @apply  mb-5 block w-full rounded-lg border border-secondary-300 bg-secondary-50 p-2.5 text-sm text-secondary-900 focus:border-primary-500 focus:ring-primary-500 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
  }

  .primary-checkbox {
    @apply h-4 w-4 rounded border-secondary-300 bg-secondary-100 text-primary-500 focus:ring-2 focus:ring-primary-300 dark:border-secondary-600 dark:bg-secondary-700 dark:ring-offset-secondary-800 dark:focus:bg-primary-300
  }

  .white-container {
    @apply flex h-full w-full grow rounded-lg bg-white p-6 shadow-axleBlue sm:p-8 dark:bg-primary-950
  }

  .axle-overflow-auto {
    @apply overflow-auto scrollbar scrollbar-track-transparent scrollbar-thumb-secondary-300;
  }
}

.axle-text-secondary-50 {
  @apply text-secondary-50 dark:text-[#1F2937]
}

.axle-text-secondary-100 {
  @apply text-secondary-100 dark:text-[#24314A]
}

.axle-text-secondary-900 {
  @apply text-secondary-900 dark:text-secondary-50
}

.axle-text-secondary-500 {
  @apply text-secondary-500 dark:text-secondary-300
}

.axle-text-secondary-300 {
  @apply text-secondary-300 dark:text-secondary-500
}

.axle-text-primary-100 {
  @apply text-primary-100 dark:text-primary-700
}

// Background
.axle-bg-white {
  @apply bg-white dark:bg-[#172135]
}

.axle-bg-button-white {
  @apply bg-white hover:bg-secondary-100 dark:bg-[#172135] dark:hover:bg-[#1F2937]
}

.axle-bg-primary-100 {
  @apply bg-primary-100 hover:bg-primary-200 dark:bg-primary-700 dark:hover:bg-primary-800
}

.axle-bg-primary-500 {
  @apply bg-primary-500 hover:bg-primary-600 
}

.axle-bg-secondary-50 {
  @apply bg-secondary-50 hover:bg-secondary-100 dark:bg-[#1F2937] dark:hover:bg-[#24314A]
}

.axle-bg-secondary-100 {
  @apply bg-secondary-100 hover:bg-secondary-200 dark:bg-secondary-700 dark:hover:bg-secondary-600
}

.axle-bg-secondary-300 {
  @apply bg-secondary-300 hover:bg-secondary-400 dark:bg-secondary-500 dark:hover:bg-secondary-400
}

.axle-bg-secondary-500 {
  @apply bg-secondary-500 hover:bg-secondary-600 dark:bg-secondary-300 dark:hover:bg-secondary-200
}

.axle-bg-secondary-900 {
  @apply bg-secondary-900 hover:bg-secondary-800 dark:bg-secondary-50 dark:hover:bg-secondary-200
}

// Border
.axle-border-white {
  @apply border-white
}

.axle-border-secondary-50 {
  @apply border-secondary-50 dark:border-[#1F2937]
}

.axle-border-secondary-100 {
  @apply border-secondary-100 dark:border-[#24314A]
}

.axle-border-secondary-200 {
  @apply border-secondary-200 
}

.axle-border-secondary-900 {
  @apply border-secondary-900 dark:border-secondary-50
}

.axle-border-secondary-500 {
  @apply border-secondary-500 dark:border-secondary-300
}

.axle-border-secondary-300 {
  @apply border-secondary-300 dark:border-secondary-500
}

.axle-border-primary-100 {
  @apply border-primary-100 dark:border-primary-700
}

.axle-border-primary-500 {
  @apply border-primary-500
}

// Focus

.axle-focus {
  @apply focus:outline-none focus:ring-4 focus:ring-primary-200
}

.axle-text-input {
  @apply block w-full rounded-lg border border-secondary-300 p-2 text-sm text-secondary-900 focus:border-primary-500 focus:ring-primary-500 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
}

.axle-search-bar {
  @apply block w-full rounded-lg border border-secondary-300 py-2 pl-9 pr-2 ps-10 text-sm text-secondary-900 focus:border-primary-500 focus:ring-primary-500 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
}


// Figure out outline button hover


.tox-tinymce {
  box-shadow: none!important;
  border: none !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tox .tox-editor-header {
  display: flex !important;
  justify-content: center!important;
}

.intercom-lightweight-app-launcher {
  @apply hidden sm:block
}

// @keyframes fade-in {
//   from {
//     opacity: 0;
//   }
// }

// @keyframes fade-out {
//   to {
//     opacity: 0;
//   }
// }

// @keyframes slide-from-right {
//   from {
//     transform: translateX(30px);
//   }
// }

// @keyframes slide-to-left {
//   to {
//     transform: translateX(-30px);
//   }
// }

// ::view-transition-old(root) {
//   animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
//     300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
// }

// ::view-transition-new(root) {
//   animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
//     300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
// }
